require('./bootstrap');

// import { Carousel } from 'bootstrap';
import { Modal } from 'bootstrap';

// LazyLoad
import LazyLoad from "vanilla-lazyload"; // add .lazy to img to lazyload
var lazyLoadInstance = new LazyLoad({
    use_native: true,
});

// Flickity
var Flickity = require('flickity');
let settings = {"wrapAround": true, "autoPlay": true, "pageDots": false, "lazyLoad": 2, "percentPosition": false}
var flkty1 = new Flickity( '.main-carousel-1', settings );
var flkty2 = new Flickity( '.main-carousel-2', settings );
var flkty3 = new Flickity( '.main-carousel-3', settings );

window.addEventListener('load', function () {
    flkty1.reloadCells()
    flkty2.reloadCells()
    flkty3.reloadCells()
});
